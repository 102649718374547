<template>
  <div>
    <div >
      <div class='table-search radius_bg'>
        <el-form inline>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.ym'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月,默认上月'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search'>查看</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--      人员操作日常统计-->
      <OptStatistics :search-condition='searchCondition' v-if='userPermissions.indexOf("live-product-opt-view")>-1' />

      <br/>

      <!--      直播排期统计-->
      <LbStatistics :search-condition='searchCondition' v-if='userPermissions.indexOf("live-product-lb-view")>-1'/>
    </div>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import LbStatistics from '@/pages/lb/dashboard/LbStatistics'
import OptStatistics from '@/pages/lb/dashboard/OptStatistics'
import { mapGetters } from 'vuex'

export default {
  name: 'OptView',
  components: { OptStatistics, LbStatistics, ExportBtn },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      searchCondition: { ym: null }
    }
  },
  created() {
    this.searchCondition.ym =this.$utils.getCurrentMonth()
    // this.searchCondition.ym =this.$utils.getLastMonth()

  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";

.panel-box {
  padding: 10px;
}
</style>
