<template>
  <div>

    <div class='panel-box radius_bg'>
      <el-row style='margin-bottom: 8px'>
        <el-col :span='16'>
          <el-link @click='handleQuery' class='title'> <i class="el-icon-s-data"></i> 直播排期统计</el-link>
        </el-col>
        <el-col :span='8' style='text-align: right'>
          <export-btn type='primary' ref='refExportLbLink' @export='handleExport'
                      :can-export='userPermissions.indexOf("live-product-lb-export")>-1'>导出列表
          </export-btn>
        </el-col>
      </el-row>
      <div class='default-table'>
        <el-table :data='dataList'  max-height='600'>
          <el-table-column label='序号' type='index' width='60' align='center'></el-table-column>
          <el-table-column label='排期日期' prop='start_at' width='140' header-align='center'
                           align='center'></el-table-column>
          <el-table-column label='排期主题' prop='theme' width='240' header-align='center' align='center'></el-table-column>
          <el-table-column label='发起人' prop='leader_name' width='120' header-align='center'
                           align='center'></el-table-column>
          <el-table-column label='初选品数' prop='selected_qty' width='140' header-align='center'
                           align='center'></el-table-column>
          <el-table-column label='确认选品数' prop='lb_ack_product_count' width='140' header-align='center'
                           align='center'></el-table-column>
          <el-table-column label='最终表格下载次数' prop='download_log_count' header-align='center'
                           align='center'></el-table-column>
        </el-table>
        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList' />
      </div>

    </div>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'LbStatistics',
  components: { ExportBtn },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      immediate: true,
      handler() {
        this.handleQuery()
      }
    }
  },
  data() {
    return {
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      dataList: [],
      orderSort: { deadline: 'asc' }
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }
      if (this.searchCondition.leader_id) {
        condition.leader_id = this.searchCondition.leader_id
      }
      return condition
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      // this.$notify.info('查询')
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { order: this.orderSort }, this.pagingData)
      let { list, pages } = await this.$api.getLbScheduleStatistics(searchCondition)
      this.dataList = list
      this.total = pages.total
      // this.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
    },
    async handleExport() {
      try {
        if (this.$refs.refExportLbLink) {
          let searchCondition = this.handleSearchCondition()
          Object.assign(searchCondition, { order: this.orderSort })
          let response = await this.$api.exportLbScheduleStatistics(searchCondition)
          let name = `直播排期统计-${searchCondition.ym}`
          this.$refs.refExportLbLink.exportSaveXlsx(response, name)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";
.title {
  font-weight: bold;
  font-size: 22px;
  margin-left: 10px;

  .el-icon-s-data {
    color: $--color--red;
  }
}
.panel-box {
  min-height: 400px;
  max-height: 800px;
}

</style>
