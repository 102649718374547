<template>
  <div>
    <init-loading :isInitLoading.sync="isInitLoading"></init-loading>

    <div class='panel-box radius_bg'>
      <el-row style='margin-bottom: 8px'>
        <el-col :span='16'>
          <el-link class='title' @click='getList'>
            <i class="el-icon-s-data"></i> 人员操作日常统计{{ searchCondition.month }}
          </el-link>
        </el-col>
        <el-col :span='8' style='text-align: right'>
          <export-btn type='primary' ref='refExportOptLink' @export="handleExport"
                      :can-export='userPermissions.indexOf("live-product-opt-export")>-1'>导出列表
          </export-btn>
        </el-col>
      </el-row>
      <div class='default-table'>
        <m-table :tableData='optDataList' max-height='600' :header-row-class-name="'header_class'">
          <el-table-column label='序号' type='index' width='100' align='center'></el-table-column>
          <el-table-column label='姓名' prop='name' header-align='center' align='center' sortable>
            <template slot-scope='{row}'>
              <span>{{ row.name || row.nickname }}</span>
            </template>
          </el-table-column>
          <el-table-column label='最后一次登录时间' prop='login_time' header-align='center'
                           align='center' sortable>
            <template slot-scope='{row}'><span>{{ $utils.parseTime(row.login_time, '{y}-{m}-{d} {h}:{i}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label='当月登录次数' prop='login_count' header-align='center'
                           align='center' sortable></el-table-column>
          <el-table-column label='当月发起选品次数' prop='lb_count' header-align='center'
                           align='center' sortable></el-table-column>
        </m-table>
      </div>
    </div>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'OptStatistics',
  components: { ExportBtn },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      immediate: true,
      handler() {
        this.getList()
      }
    }
  },
  data() {
    return {
      optDataList: [],
      isInitLoading: true
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = { }
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }
      // if (this.searchCondition.leader_id) {
      //   condition.leader_id = this.searchCondition.leader_id
      // }
      return condition
    },
    async getList() {
      this.optDataList = []
      if (this.searchCondition.ym) {
        let searchCondition = this.handleSearchCondition()
        let { list } = await this.$api.getLbScheduleOptStatistics(searchCondition)
        this.optDataList = list
        this.isInitLoading = false
      }

    },
    async handleExport(){
      try {
        if(this.$refs.refExportOptLink){
          let searchCondition = this.handleSearchCondition()

          let response = await this.$api.exportLbScheduleOptStatistics(searchCondition)
          let name = `人员操作日常统计-${this.searchCondition.ym}`

          this.$refs.refExportOptLink.exportSaveXlsx(response, name)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";

.title {
  font-weight: bold;
  font-size: 22px;
  margin-left: 10px;

  .el-icon-s-data {
    color: $--color--red;
  }
}

.panel-box {
  min-height: 400px;
  max-height: 800px;
}

::v-deep .header_class {
  background-color: #fff7f8 !important;
}
</style>
